
import React from 'react';
import SearchField from '../components/input/SearchField.js'
import '../styles/FinancePage.css'


class FinancePage extends React.Component{
    constructor(props){
        super(props);
        this.handler = this.handler.bind(this);
        this.state = {
            input_from_searchfield: "",
            finance_data_response: {}
        }
    }

    handler(val){
        this.setState({input_from_searchfield: val},
            function(){console.log("Updated the input value of the searchfield on the finance page to: " + this.state.input_from_searchfield)}
            );
        this.retrieveFinanceData(this.state.input_from_searchfield);
    }

    retrieveFinanceData(searchterm){
        console.log("Retrieve finance data for keyword: " + searchterm);
        
        let msfinance_url = `https://ms-finance.p.rapidapi.com/market/v2/auto-complete?q=${searchterm}&json=true`
        let ms_finance_headers = {
            "x-rapidapi-host": "ms-finance.p.rapidapi.com",
            "x-rapidapi-key": "88e1f94820msh71edc7ac6627435p152508jsnb36d3fc44c43"
        }

        let yahoo_finance_url = "https://yfapi.net/v11/finance/quoteSummary/AAPL"
        let yahoo_finance_headers = {
            'x-api-key': '7o70yRlpG8F2sHh6YGB752iOkMUILyCa2AJ6jGE2'
        }
        
        var axios = require("axios").default;

        var options = {
            method: 'GET',
            url: yahoo_finance_url,
            params: {modules: 'defaultKeyStatistics,assetProfile'},
            headers: yahoo_finance_headers
        };

        axios.request(options)
        .then(response => { 
            let data = response.data.quoteSummary.result[0]
            console.log(data);
            this.setState({finance_data_response: data},
                         function(){console.log("Update finance date from response: " + this.state.finance_data_response)}
                         );  
        }).catch(function (error) {
            console.error(error);
        });

    }

    render(){
        return(
            <div className='finance-root'>
                <SearchField name="keyword_search" handler={this.handler}/>
            </div>
        );
    }
}

export default FinancePage;