import React from 'react'
import BlogEntry from '../components/content/BlogEntry';
import BlogOverview from '../components/content/BlogOverview';


class TechBlog extends React.Component{
  constructor(props){
      super(props);
      this.state = {
        blogElement: {},
        blogEntryHasBeenChoosen: props.chooseBlogEntry
      }

      this.blogentries = require('../components/content/blogentries.json')
      console.log(this.blogentries)
  }

  blogCallback = (blogEl) => {
    console.log("callback executed")
    this.setState({blogEntryHasBeenChoosen: true})
    this.setState({blogElement: blogEl})
  }

  setOverview = () => {
    this.setState({blogEntryHasBeenChoosen: false})
    
  }

  render(){

      return(
        <>
          {this.state.blogEntryHasBeenChoosen ? (
            <BlogEntry title={this.state.blogElement.title} image={this.state.blogElement.image} 
            text={this.state.blogElement.article} getOverviewHandler={this.setOverview} />
          ) : (
            <BlogOverview blogentries={this.blogentries} blogCallback={this.blogCallback} />
          )}
        </>         
      );
  }
}

export default TechBlog;

        