import React from 'react'

import '../../styles/Tab.css'

class Tab extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            name: props.name,
            value: props.value,
            handler: props.handler
        }
    }

    render(){
        return(
            <div className='Tab glow-on-hover' onClick={() => this.state.handler(this.state.value)}>
                {this.state.name}    
            </div>
        );
    }
}

export default Tab;