import { BrowserRouter, Routes, Route, Switch, Link, useNavigate } from 'react-router-dom';

import './styles/App.css';
import Main from './components/navigation/Main.js'
import Footer from './components/navigation/Footer.js'

function App() {
  return (
    <div className='App'>
      
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route path="/about" element={
            <a href='https://www.google.at'>click me to switch to google.at</a>} />
        </Routes>
      </BrowserRouter>

      <Footer />
      
    </div>
  );
}

export default App;
