import React from 'react'

class LoggingButton extends React.Component {
    handleClick() {
      console.log('this is:', this);
    }
  
    render() {
        return (      
        <button onClick={() =>{
            this.handleClick()
            alert("got clicked")
        }}>Click my button</button>
      );
    }
  }


const HomePage = () => {
    return(
        <div>
            <span> You are on the home page</span>
            {new LoggingButton().render()}
        </div>
    );
}

export default HomePage;