import React from 'react'
import '../../styles/BlogOverview.css'

class BlogOverview extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            blogOverviewIcons : [],
            blogentries: props.blogentries
        }
        
        this.blogCallback = props.blogCallback;
    }



    render(){
        this.state.blogOverviewIcons = []
        this.state.blogentries.forEach( (item, idx) => {
            this.state.blogOverviewIcons.push(
                <div class="BlogIconItem" onClick={() => {this.blogCallback(item)}}>
                    <span>{item.title}</span>
                    <div class="IconImage">
                        <img src={item.image} alt="Icon image" />
                    </div>
                </div>)
        })

        return(
            <div class="BlogIconOverview">
                {this.state.blogOverviewIcons}
            </div>
        );
    }
}

export default BlogOverview;