import React from 'react'
//import { RetrieveYahooData } from '../../functionalities/Functions.js'

class SearchField extends React.Component{

    constructor(props){
        super(props); 
        console.log("Created Searchfield constructor: " + props.name);
        this.state = {
            finance_data: {},
            search_keyword: "",
            handler: props.handler
        }
    }

    setKeyword(kw){
        this.setState({search_keyword: kw}, 
            function(){console.log("The search keyword is now: " + this.state.search_keyword)}
            );
    }

    render(){
        return(
            <div>
                <label htmlFor='header-search'><span>Search for Finance Data: </span></label>
                <input placeholder='Input Search Term' type="text" 
                    value={this.state.search_keyword} 
                    onChange={(textinput) => this.setKeyword(textinput.target.value)}
                    onInput={(textinput) => this.setKeyword(textinput.target.value)}></input>
                <button type='button' onClick={() => this.state.handler(this.state.search_keyword)}>Search</button>
            </div>
        );
    }
}

export default SearchField;