import React from 'react'

import FinancePage from "../../pages/FinancePage.js";
import HomePage from "../../pages/HomePage.js";
import Tab from "../tabComponent/Tab.js";
import TechBlog from '../../pages/TechBlog.js';

class Main extends React.Component{
    constructor(props){
        super(props);
        this.handler = this.handler.bind(this);
        this.state = {
            site: 0
        }
    }

    // introduce handler for content switch when clicking on Tab
    handler(val){
        this.setState({site: val}, 
            function(){console.log("Load new content site: " + this.state.site)} 
            );
    }

    getContent(){
        switch(this.state.site){
            case '0':
                return <HomePage />;
            case '1': 
                return <FinancePage />;
            case '2':
                return "About will come..";
            case '3':
                return <TechBlog chooseBlogEntry={false} />;
            default:
                return <HomePage />;
        }
    }

    render(){
        return(
            <div className='Main'>
                <div className='Menu'>
                    <Tab name="Home" value="0" handler={this.handler} />
                    <Tab name="Finance" value="1" handler={this.handler} />
                    <Tab name="About" value="2" handler={this.handler} />
                    <Tab name="Techblog" value="3" handler={this.handler} />
                </div>
                
                <div className='Content'>
                    {this.getContent()}
                </div>
            </div>
        );
    }
}

export default Main;