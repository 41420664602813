import React from 'react'
//import { RetrieveYahooData } from '../../functionalities/Functions.js'
import "../../styles/BlogEntry.css"
import {Link, Routes, Route, useNavigate } from 'react-router-dom';

class BlogEntry extends React.Component{

    constructor(props){
        super(props); 
        console.log("Created BlogEntry constructor: " + props.name);
        this.state = {
            title: props.title,
            blog_text: props.text,
            image_path: props.image,
            handler: props.getOverviewHandler
        }
    }

    render(){        
        return(
            
            <div class="blogStory">
                <button class="blogToOverview" onClick={ () => {this.state.handler()} }> Back to Overview </button>

                <h1>{this.state.title}</h1>

                <div class="blogImage">
                    <img src={this.state.image_path} alt="Best Fitting Blog" />
                </div>

                <div className='blogText'><span>{this.state.blog_text}</span></div>
            </div>
        );
    }
}

export default BlogEntry;
